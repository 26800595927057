.main-container {
    display: flex; /* Use flex layout */
  }

.sidebar {
/*width: 240px;*/ /* Adjust the width of the sidebar */
/* Your existing styles for the sidebar */

}

.content {
flex: 1; /* Allow content to take remaining space */
/* Your existing styles for the content */
}

body {
    margin: 0;
    font-family: "Figtree", -apple-system, BlinkMacSystemFont, "Segoe UI", "Figtree", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100vh;
    background: #ffffff;
    font-size: 24px;
}
.sidebar{
    height: 100%
}
.menu1 {
    margin-bottom: 40px;
    margin-top: 20px;
}
h2 {
    color: #165a72;
}